












































@import '~#/variables';

.testdrive {
    text-transform: none;
    background-color: transparent;
    border-bottom: 1px solid transparentize($c-white, 0.5);
    display: flex;
    align-items: center;

    font-weight: bold;
    padding: $s-button-padding;

    &__details {
        flex-grow: 1;
    }

    &__status.active {
        color: #aadd00;
    }
}
