@import '~normalize.css';
@import '~typeface-raleway';
@import '~#/variables';

* {
    box-sizing: border-box;
    outline: none !important;
}

html {
    height: 100%;
}

body {
    color: $c-dark-gray;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    min-height: 100%;
    display: flex;
}

a {
    color: $c-white;
    text-decoration: none;
}

button, a.button {
    background-color: $c-button-bg;
    color: $c-button-fg;
    border: none;
    border-radius: $s-button-border-radius;
    padding: $s-button-padding;
    cursor: pointer;
    transition: all .2s;
    font-weight: bold;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover,
    &.active {
        background-color: darken($c-button-bg, 5);
    }

    &.in-content {
        width: 100%;
        display: block;
        text-align: center;
    }

    &.alt {
        color: $c-white;
        background-color: $c-green;

        &:active,
        &:focus,
        &:hover,
        &.active {
            background-color: darken($c-green, 5);
        }
    }

    &.destructive {
        color: $c-white;
        background-color: $c-button-destructive-bg;

        &:active,
        &:focus,
        &:hover,
        &.active {
            background-color: darken($c-button-destructive-bg, 5);
        }
    }

    &:disabled {
        background-color: $c-button-bg-disabled;
        color: $c-button-fg-disabled;

        &:hover {
            cursor: auto;
        }
    }
}

input {
    background-color: $c-input-bg;
    color: $c-input-fg;
    border: none;
    border-radius: $s-input-border-radius;
    padding: $s-input-padding;
}

select {
    background-color: $c-input-bg;
    color: $c-input-fg;
    border: none;
    border-radius: $s-input-border-radius;
    padding: 10px 15px;
}

.input-wrapper {
    display: block;
}

.input--full-width {
    display: block;
    width: 100%;
    margin: 5px 0;
}

h1, h2, h3 {
    color: $c-white;
    margin: 0;
    line-height: 1.35;
}

h1 {
    font-size: $s-font-h1;
    padding-top: 24px;
}

h2 {
    font-size: $s-font-h2;
    padding: 20px 0;
}

h3 {
    font-size: $s-font-h3;
    padding: 20px 0;
}

p {
    font-size: $s-font-body;
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, $c-blue 0%, darken($c-blue, 10) 50%, darken($c-blue, 20) 100%);
}

.content {
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}

.content__main {
    width: 100%;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.content__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20px 10px;

    &__action {
        @extend a.button;
        margin-bottom: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.scrollable {
    overflow-y: auto;
    height: 100%;

    > h1,
    > h2,
    > h3 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 20px;
    padding-top: 0px;

    input {
        padding: 10px 20px;
        margin-bottom: 20px;
        width: 100%;
    }

    img {
        width: 100%;
    }

    a {
        width: 100%;
    }
}

.summary {
    color: $c-white;
    width: 100%;
    padding: 0 20px 20px;

    &__title {
        margin: 0;
        font-size: 1.2em;
    }

    &__spec {
        width: 100%;
        padding: 5px 0;

        &__value {
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__link {
        display: block;
        width: 100%;
        padding: 10px 0;
        margin-top: 10px;
        font-weight: bold;
        text-decoration: underline;
    }
}

img.summary__spec__value {
    max-width: 100%;
    max-height: 100px;
    margin: 5px 0;
}
