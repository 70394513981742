

































































































































@import '~#/variables';

.photo {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}
