











































































































@import '~#/variables';

.create {
    margin-bottom: 20px;
}

.no-users-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}
