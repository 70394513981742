




















































































































































































@import '~#/variables';

.two-columns {
    display: flex;
    align-items: center;
    color: $c-white;
    margin-bottom: 10px;

    > * {
        flex: 1;
        margin-bottom: 0;
        min-width: 25%;
    }

    > input {
        max-width: 75%;
    }
}

p {
    color: $c-white;
}

.button.in-content.destructive {
    margin-top: 30px;
}
