
















































































































































































































































































































































































































































@import '~#/variables';

.two-columns {
    display: flex;
    align-items: center;
    color: $c-white;
    margin-bottom: 10px;

    > * {
        flex: 1;
        margin-bottom: 0;
        max-width: 50%;
    }
}

.simple-value {
    font-weight: bold;
}

.notice {
    color: $c-white;
    margin: 0 0 5px;
    font-weight: bold;
}

.months-button {
    position: relative;
    padding: 10px;
    text-align: left;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: url(images/forward.png) center/contain no-repeat;
        height: 100%;
        width: 50px;
    }
}

.radios {
    display: flex;
    width: 100%;
    background-color: $c-white;
    border-radius: $s-border-radius;
    margin-bottom: 10px;

    label {
        display: block;
        width: 50%;
        padding: 2px;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $c-blue;
        text-align: center;
        padding: 10px 20px;
        border-radius: $s-border-radius;
        cursor: pointer;
    }

    input:checked ~ .title {
        background-color: $c-green;
        color: $c-white;
        font-weight: bold;
    }

    input {
        display: none;
    }
}
