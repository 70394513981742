













@import '~#/variables';

.user {
    display: flex;
    padding: $s-button-padding;
    border-bottom: 1px solid transparentize($c-white, 0.5);

    &:last-child {
        margin-bottom: 20px;
    }
}
