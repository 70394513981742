

















@import '~#/variables';

.error-indicator {
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $c-white;
}
