











































































@import '~#/variables';

h3 {
    padding: 20px;
}

.no-testdrives-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.deletion-notice {
    padding: $s-button-padding;
    color: $c-white;
}
