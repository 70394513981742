
























































































@import '~#/variables';

.no-users-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
