
































@import '~#/variables';

p {
    padding: 0 20px;
    color: $c-white;
}
