




































































@import '~#/variables';

.page {
    align-items: center;
    justify-content: center;
}

.logo {
    margin-bottom: 20px;
    width: 100%;
    max-width: 250px;
}

.failed {
    text-align: center;
    color: $c-red;
}

.login-form {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 20px;
    border-radius: 5px;
    background-color: $c-white;

    input {
        padding: 10px 20px;
        margin-bottom: 10px;
    }

    button {
        margin-bottom: 10px;
    }
}
