
























































































@import '~#/variables';

.contact-buttons {
    display: flex;
    padding: 10px;
    background-color: transparentize($c-white, 0.8);

    a {
        display: flex;
        flex-grow: 1;
        height: 50px;
        padding: 12px;

        &:not(:first-child) {
            margin-left: 10px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.testdrives {
    overflow-y: auto;
}

.no-testdrives-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
